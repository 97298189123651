import { getSessionValue } from '../session'

type Utms = {
  medium?: string
  campaign?: string
  parceiro?: string
}

export const buildSimulatorUrl = (pathname: string, utm: Utms = {}, withValuesInSession = false) => {
  const origin = getSessionValue('origem')
  const originPage = getSessionValue('originPage')
  const campaignData = getSessionValue('campaignData')
  const propertyValue = getSessionValue('propertyValue')
  const loanAmount = getSessionValue('loanAmount')
  const state = getSessionValue('state')
  const personType = getSessionValue('personType')
  const isUserFromBlog = originPage?.match(/blog\/.*/) !== null
  const firstPage = originPage === '/' ? 'Home' : _pathToCamelCase(originPage)
  const currentPage = pathname === '/' ? 'Home' : _pathToCamelCase(pathname)
  const utm_medium = utm.medium ? _pathToCamelCase(utm.medium) : false
  const utm_campaign = utm.campaign ? utm.campaign : false
  const newSimulatorLink = withValuesInSession
    ? process.env.NEXT_PUBLIC_SIMULATOR_LINK_WITH_VALUES
    : process.env.NEXT_PUBLIC_SIMULATOR_LINK
  const formValuesInSession = withValuesInSession ? { propertyValue, loanAmount, personType, state } : {}

  const addQueryToUrl = search => `${newSimulatorLink}?${search}`

  const cleanObject = object =>
    Object.keys(object).reduce((acc, key) => {
      if (object[key]) return { ...acc, [key]: object[key] }
      return acc
    }, {})

  if (campaignData?.includes('utm_source')) {
    let query = campaignData?.substring(1)
    if (withValuesInSession) query = query.concat('&', _objectToQueryString(formValuesInSession))

    return addQueryToUrl(query)
  }

  if (isUserFromBlog) {
    const url_utms = window.location.search
    let new_utm_medium: string | null = ''
    
    if(url_utms.includes('utm_medium')){
      const utms = new URLSearchParams(url_utms)
      new_utm_medium = utms.get('utm_medium')
    }

    const search = {
      utm_group: 'organic',
      utm_source: 'Blog',
      utm_medium: new_utm_medium || utm_medium || 'search',
      utm_term: firstPage,
      utm_campaign: utm_campaign || currentPage,
      parceiro: utm.parceiro || '',
      ...formValuesInSession
    }
    return addQueryToUrl(_objectToQueryString(cleanObject(search)))
  }

  if (origin === 'direto') {
    const search = {
      utm_group: 'Direto',
      utm_source: 'Direto',
      utm_medium: utm_medium || 'direto',
      utm_term: firstPage,
      utm_campaign: utm_campaign || currentPage,
      parceiro: utm.parceiro || '',
      ...formValuesInSession
    }
    return addQueryToUrl(_objectToQueryString(cleanObject(search)))
  }

  const search = {
    utm_group: 'organic',
    utm_source: 'Site (Orgânico)',
    utm_medium: utm_medium || 'organico',
    utm_term: firstPage,
    utm_campaign: utm_campaign || currentPage,
    parceiro: utm.parceiro || '',
    ...formValuesInSession
  }

  return addQueryToUrl(_objectToQueryString(cleanObject(search)))
}

const _objectToQueryString = object => {
  const search = new URLSearchParams('')
  Object.entries(object).forEach(([key, value]) => {
    search.set(key, value as string)
  })
  return search.toString().replace(/\+/g, '%20')
}

const _pathToCamelCase = path =>
  path
    .replace(/\/|\s/g, '|')
    .split('|')
    .reduce((accumulator, word) => `${accumulator}${_capitalizeFirstLetter(word)}`, '')

const _capitalizeFirstLetter = word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`
